.RelatedArticles_wrap {
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  grid-column: 1/13;
  margin-top: 40px;
}

.RelatedArticles_wrap > * {
  margin-bottom: 20px;
}

@media (max-width: 1024px) {
  .RelatedArticles_wrap > * {
    margin-bottom: 10px;
  }
}
